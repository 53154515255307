import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import NavBar from "../components/header"
import { Button } from "react-bootstrap"
import Layout from "../components/layout"

export default function Home() {
  return (
    <div>
      <Layout>
        <div>Hello world!</div>
        <Button variant="primary">Primary</Button> <h1>hi</h1>
      </Layout>
    </div>
  )
}
